import React from 'react';
import { Box, Button, Typography } from '@heycater/design-system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import BreakfastImg from '@images/homepage/lps/breakfast.jpeg';
import BuffetImg from '@images/homepage/lps/buffet.jpeg';
import ChristmasImg from '@images/homepage/lps/christmas.jpeg';
import CocktailsImg from '@images/homepage/lps/cocktails.jpeg';
import CoffeeImg from '@images/homepage/lps/coffee.jpeg';
import EventsImg from '@images/homepage/lps/events.jpeg';
import FingerfoodImg from '@images/homepage/lps/fingerfood.jpeg';
import FoodtruckImg from '@images/homepage/lps/foodtruck.jpeg';
import GrillImg from '@images/homepage/lps/grill.jpeg';
import SummerpartyImg from '@images/homepage/lps/summerparty.jpeg';
import VeganImg from '@images/homepage/lps/vegan.jpeg';
import FullWidthSlider from 'shared/components/FullWidthSlider';
import ImageCard from 'static_pages/components/ImageCard';

export default function RightCatering() {
  const { t } = useTranslation('homepage');
  const items = [
    {
      key: 'fingerfood',
      href: 'https://www.heycater.com/de/fingerfood',
      cta: t('rightCatering.cards.fingerfood'),
      pillText: '',
      imageSrc: FingerfoodImg,
      ctaTextAlign: 'left',
    },
    {
      key: 'fruehstueck',
      href: 'https://www.heycater.com/de/fruehstueck',
      cta: t('rightCatering.cards.breakfast'),
      pillText: '',
      imageSrc: BreakfastImg,
      ctaTextAlign: 'left',
    },
    {
      key: 'food-truck',
      href: 'https://www.heycater.com/de/food-truck',
      cta: t('rightCatering.cards.foodtruck'),
      pillText: '',
      imageSrc: FoodtruckImg,
      ctaTextAlign: 'left',
    },
    {
      key: 'event',
      href: 'https://www.heycater.com/de/event',
      cta: t('rightCatering.cards.events'),
      pillText: '',
      imageSrc: EventsImg,
      ctaTextAlign: 'left',
    },
    {
      key: 'buffet',
      href: 'https://www.heycater.com/de/buffet',
      cta: t('rightCatering.cards.buffet'),
      pillText: '',
      imageSrc: BuffetImg,
      ctaTextAlign: 'left',
    },
    {
      key: 'cocktail',
      href: 'https://www.heycater.com/de/bar-cocktail',
      cta: t('rightCatering.cards.cocktail'),
      pillText: '',
      imageSrc: CocktailsImg,
      ctaTextAlign: 'left',
    },
    {
      key: 'kaffeebar',
      href: 'https://www.heycater.com/de/mobile-kaffeebar',
      cta: t('rightCatering.cards.coffee'),
      pillText: '',
      imageSrc: CoffeeImg,
      ctaTextAlign: 'left',
    },
    {
      key: 'vegan',
      href: 'https://www.heycater.com/de/vegan',
      cta: t('rightCatering.cards.vegan'),
      pillText: '',
      imageSrc: VeganImg,
      ctaTextAlign: 'left',
    },
    {
      key: 'grill',
      href: 'https://www.heycater.com/de/grill-catering',
      cta: t('rightCatering.cards.grill'),
      pillText: '',
      imageSrc: GrillImg,
      ctaTextAlign: 'left',
    },
    {
      key: 'sommerfest',
      href: 'https://www.heycater.com/de/sommerfest',
      cta: t('rightCatering.cards.summerparty'),
      pillText: '',
      imageSrc: SummerpartyImg,
      ctaTextAlign: 'left',
    },
    {
      key: 'firmenfeier',
      href: 'https://www.heycater.com/de/catering-firmenfeier',
      cta: t('rightCatering.cards.christmas'),
      pillText: '',
      imageSrc: ChristmasImg,
      ctaTextAlign: 'left',
    },
  ];

  return (
    <Wrapper>
      <Title>{t('rightCatering.title')}</Title>
      <Subtitle>{t('rightCatering.subtitle')}</Subtitle>
      <Box my={4}>
        <FullWidthSlider scrollButtonOffset={{ top: 80 }}>
          {items.map((item) => (
            <ImageCard
              key={item.key}
              href={item.href}
              cta={item.cta}
              pillText={item.pillText}
              imageSrc={item.imageSrc}
              ctaTextAlign={item.ctaTextAlign}
            />
          ))}
        </FullWidthSlider>
      </Box>
      <Box display="flex" justifyContent="center">
        <a href="#qf-start">
          <StyledButton variant="primary">
            {t('rightCatering.cta')}
          </StyledButton>
        </a>
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 2)};
  overflow: hidden;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0;
  }
`;

const Title = styled(Typography)`
  font-size: 20px;
  line-height: 34.5px;
  font-weight: 700;
  text-align: left;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 36px;
    line-height: 62.1px;
    text-align: center;
  }
`;

const Subtitle = styled(Typography)`
  font-size: 14px;
  line-height: 23.52px;
  font-weight: 400;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  text-align: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 16px;
    line-height: 26.88px;
  }
`;

const StyledButton = styled(Button)`
  border-radius: 47px;
`;
